import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';

const style = {
  shapeRendering: 'auto',
};

function Blocks({ size = 42, className }) {
  const theme = useTheme();
  const animateColorsValue = `${theme.palette.secondary.dark};${theme.palette.primary.light};${theme.palette.primary.light}`;

  return (
      <svg
          width={`${size}px`}
          height={`${size}px`}
          viewBox={`0 0 ${size / 2} ${size / 2}`}
          preserveAspectRatio="xMidYMid"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx('bg-default m-auto block', className)}
          style={style}
      >
        <rect x={`${size / 10 - 1}`} y={`${size / 10 - 1}`} width={`${size / 10}`} height={`${size / 10}`}
              fill="var(--color-primary-light)">
          <animate attributeName="fill"
                   values={animateColorsValue}
                   keyTimes="0;0.125;1" dur="1s"
                   repeatCount="indefinite" begin="0s" calcMode="discrete" />
        </rect>
        <rect x={`${size / 5}`} y={`${size / 10 - 1}`} width={`${size / 10}`} height={`${size / 10}`}
              fill="var(--color-primary-light)">
          <animate attributeName="fill"
                   values={animateColorsValue}
                   keyTimes="0;0.125;1" dur="1s"
                   repeatCount="indefinite" begin="0.125s" calcMode="discrete" />
        </rect>
        <rect x={`${(size / 10) * 3 + 1}`} y={`${size / 10 - 1}`} width={`${size / 10}`} height={`${size / 10}`}
              fill="var(--color-primary-light)">
          <animate attributeName="fill"
                   values={animateColorsValue}
                   keyTimes="0;0.125;1" dur="1s"
                   repeatCount="indefinite" begin="0.25s" calcMode="discrete" />
        </rect>
        <rect x={`${size / 10 - 1}`} y={`${size / 5}`} width={`${size / 10}`} height={`${size / 10}`}
              fill="var(--color-primary-light)">
          <animate attributeName="fill"
                   values={animateColorsValue}
                   keyTimes="0;0.125;1" dur="1s"
                   repeatCount="indefinite" begin="0.875s" calcMode="discrete" />
        </rect>
        <rect x={`${(size / 10) * 3 + 1}`} y={`${size / 5}`} width={`${size / 10}`} height={`${size / 10}`}
              fill="var(--color-primary-light)">
          <animate attributeName="fill"
                   values={animateColorsValue}
                   keyTimes="0;0.125;1" dur="1s"
                   repeatCount="indefinite" begin="0.375s" calcMode="discrete" />
        </rect>
        <rect x={`${size / 10 - 1}`} y={`${(size / 10) * 3 + 1}`} width={`${size / 10}`} height={`${size / 10}`}
              fill="var(--color-primary-light)">
          <animate attributeName="fill"
                   values={animateColorsValue}
                   keyTimes="0;0.125;1" dur="1s"
                   repeatCount="indefinite" begin="0.75s" calcMode="discrete" />
        </rect>
        <rect x={`${size / 5}`} y={`${(size / 10) * 3 + 1}`} width={`${size / 10}`} height={`${size / 10}`}
              fill="var(--color-primary-light)">
          <animate attributeName="fill"
                   values={animateColorsValue}
                   keyTimes="0;0.125;1" dur="1s"
                   repeatCount="indefinite" begin="0.625s" calcMode="discrete" />
        </rect>
        <rect x={`${(size / 10) * 3 + 1}`} y={`${(size / 10) * 3 + 1}`} width={`${size / 10}`} height={`${size / 10}`}
              fill="var(--color-primary-light)">
          <animate attributeName="fill"
                   values={animateColorsValue}
                   keyTimes="0;0.125;1" dur="1s"
                   repeatCount="indefinite" begin="0.5s" calcMode="discrete" />
        </rect>
      </svg>
  );
}

export default React.memo(Blocks);
