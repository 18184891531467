import React, { Suspense, useEffect } from 'react';
import { useRouter } from 'next/router';
import Header from '@ui-kit/components/Header/Header';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import useThemeVar from '@ui-kit/layout/hooks/useThemeVar';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import moment from '@utils/momentInstance';
import NavigationBackdrop from '@ui-kit/layout/components/NavigationBackdrop';
import Metrics from '@ui-kit/layout/components/metrics';
import { snackBarAnchorOrigin, snackBarClasses } from './constants';

function Layout({ children }) {
  useThemeVar();

  const { locale } = useRouter();

  useEffect(() => {
    if (moment.locale() !== locale) {
      moment.locale(locale);
    }
  }, [locale]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
        <ConfirmProvider>
          <SnackbarProvider maxSnack={3} anchorOrigin={snackBarAnchorOrigin} classes={snackBarClasses}>
            <Header />
            <div className="container flex flex-col flex-1 py-8 w-full min-h-screen">
              {children}
            </div>
            <NavigationBackdrop />
          </SnackbarProvider>
        </ConfirmProvider>
      </LocalizationProvider>
      <Suspense>
        <Metrics />
      </Suspense>
    </>
  );
}

export default Layout;
