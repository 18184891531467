import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { applyTheme } from '@utils/themeUtils';

function useThemeVar() {
  const theme = useTheme();

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);
}

export default useThemeVar;
