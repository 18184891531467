import React from 'react';
import clsx from 'clsx';
import map from 'lodash/map';
import { ClickAwayListener, Divider, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import Button from '@ui-kit/components/Button/Button';

function Dropdown({
  buttonContent,
  dropdownList,
  dropdownHeader,
  caret,
  left,
  rtlActive,
  className,
}) {
  const anchorEl = React.useRef();
  const [openPopper, setOpenPopper] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setOpenPopper(prevState => !prevState);
  }, []);

  const handleCloseAway = React.useCallback(() => {
    setOpenPopper(false);
  }, []);

  const caretClasses = clsx({
    'transition-all inline-block w-0 h-0 ml-1 align-middle': true,
    'border-solid border-r-4 border-t-4 border-l-4 border-x-transparent': true,
    'rotate-180': openPopper,
    'mr-1': rtlActive,
  });
  const dropdownItem = clsx({
    'text-sm truncate px-5 py-2.5 my-0 mx-[5px] rounded-[2px]': true,
    'relative transition-all block clear-both h-content whitespace-nowrap text-secondary': true,
    'hover:shadow-1 hover:bg-paper hover:text-primary': true,
    'align-right': rtlActive,
  });

  return (
    <>
      <Button
        ref={anchorEl}
        aria-owns="menu-list"
        aria-haspopup="true"
        variant="outlined"
        className={clsx('nav-link', className)}
        onClick={handleClick}
      >
        {buttonContent ?? null}
        {caret ? <b className={caretClasses} /> : null}
      </Button>
      <Popper
        open={openPopper}
        anchorEl={anchorEl.current}
        disablePortal
        placement={left ? 'bottom-start' : 'bottom'}
        className={clsx('popper-responsive', { 'pointer-events-none': !openPopper })}
      >
        <Paper
          className="rounded-3 border-0 shadow-sm z-999 min-w-[7rem] py-[5px] px-0 mt-[2px] text-left text-sm bg-clip-padding"
        >
          <ClickAwayListener onClickAway={handleCloseAway}>
            <MenuList role="menu" className="p-0">
              {dropdownHeader !== undefined ? (
                <MenuItem
                  className="block py-[0.1875rem] px-5 text-sm text-grey-400 whitespace-nowrap mt-1.5 truncate
                              hover:bg-transparent hover:cursor-auto focus:bg-transparent focus:cursor-auto"
                >
                  {dropdownHeader}
                </MenuItem>
              ) : null}
              <Divider className="my-[5px] mx-0 bg-black/[.12] overflow-hidden h-[1px]" />
              {map(dropdownList, (prop, key) => {
                if (prop.divider) {
                  return (
                    <Divider
                      key={key}
                      className="my-[5px] mx-0 bg-black/[.12] overflow-hidden h-[1px]"
                    />
                  );
                }
                const handleClick = (e) => {
                  setOpenPopper(false);
                  prop.onClick?.(e);
                };
                return (
                  <MenuItem
                    key={key}
                    onClick={handleClick}
                    className={dropdownItem}
                  >
                    {prop.value}
                  </MenuItem>
                );
              })}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}

export default React.memo(Dropdown);
