import React from 'react';
import Link from 'next/link';
import { AppBar, Button, Drawer, Hidden, IconButton, Toolbar } from '@mui/material';
import RightSide from '@ui-kit/components/Header/RightSide';
import Navigation from '@ui-kit/components/Header/Navigation';
import { Menu } from '@mui/icons-material';
import { useRouter } from 'next/router';
import { USER_TOKEN_COOKIE_NAME } from 'constants/user';
import isEmpty from 'lodash/isEmpty';
import { getCookie } from 'cookies-next';
import mainLogo from '@ui-kit/layout/main_logo_2.svg';
import NextImage from 'next/image';

const appBarClasses = {
  root: 'main-header py-1.5',
  colorInherit: 'text-primary',
};
const drawerClasses = {
  paper: 'drawer-paper p-2.5 flex flex-col flex-wrap',
};

function Header() {
  const token = getCookie(USER_TOKEN_COOKIE_NAME);
  const userIsLoggedIn = !isEmpty(token);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pathname } = useRouter();

  React.useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(prevState => !prevState);
  }, []);

  return (
    <AppBar
      color="inherit"
      position="sticky"
      component="nav"
      classes={appBarClasses}
    >
      <Toolbar className="container gap-1" disableGutters>
        <Link shallow={true} prefetch={false} href="/">
          <Button
            className="h-16 rounded-3 px-2 -ml-2 sm:px-4 sm:-ml-4 hover:bg-transparent focus:bg-transparent"
          >
            <NextImage
              alt="Is for me"
              src={mainLogo}
              className="w-52 pt-1.5 sm:pt-3.5"
            />
          </Button>
        </Link>
        <div className="mr-auto">
          <Hidden xlDown implementation="css">
            <Navigation />
          </Hidden>
        </div>
        <RightSide />
        <Hidden xlUp>
          {userIsLoggedIn &&
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu fontSize="inherit" className="text-xl" />
            </IconButton>
          }
        </Hidden>
      </Toolbar>
      <Hidden xlUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          classes={drawerClasses}
          onClose={handleDrawerToggle}
        >
          <Navigation vertical />
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

export default Header;
