import React, { useCallback } from 'react';
import Button from '@ui-kit/components/Button/Button';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';

function LanguageButton({ language, title }) {
  const { pathname, asPath, query, push, locale } = useRouter();
  const setLocale = useCallback(() => {
    push({ pathname, query }, asPath, {
      locale: language,
    });
  }, [asPath, language]);

  return (
    <Button variant="outlined" onClick={setLocale}>
      <Typography
        variant="inherit"
        color={locale === language ? 'primary.main' : 'text.secondary'}
      >
        {title ?? language}
      </Typography>
    </Button>
  );
}

export default React.memo(LanguageButton);
