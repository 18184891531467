import { useEffect } from 'react';
import { getCookie } from 'cookies-next';
import { USER_TOKEN_COOKIE_NAME } from '../../constants/user';
import isEmpty from 'lodash/isEmpty';

export function loginToYandexID() {
  const clientId = process.env.NEXT_PUBLIC_YA_CLIENT_ID;
  const redirectUri = `${window.location.origin}/token`;
  const currentPath = window.location.pathname;
  const state = encodeURIComponent(currentPath === '/token' ? '/' : currentPath);
  window.location.href = `https://oauth.yandex.ru/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
}

function useLogin() {
  const token = getCookie(USER_TOKEN_COOKIE_NAME);

  useEffect(() => {
    if (isEmpty(token)) {
      loginToYandexID();
    }
  }, [token]);
}

export default useLogin;
