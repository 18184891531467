const mapTheme = (theme) => {
  return {
    '--color-primary-main': theme?.palette?.primary?.main ?? '',
    '--color-primary-light': theme?.palette?.primary?.light ?? '',
    '--color-primary-dark': theme?.palette?.primary?.dark ?? '',
    '--color-primary-contrastText': theme?.palette?.primary?.contrastText ?? '',

    '--color-secondary-main': theme?.palette?.secondary?.main ?? '',
    '--color-secondary-light': theme?.palette?.secondary?.light ?? '',
    '--color-secondary-dark': theme?.palette?.secondary?.dark ?? '',
    '--color-secondary-contrastText': theme?.palette?.secondary?.contrastText ?? '',

    '--color-divider': theme?.palette?.divider ?? '',

    '--color-grey-50': theme?.palette?.grey?.['50'] ?? '',
    '--color-grey-100': theme?.palette?.grey?.['100'] ?? '',
    '--color-grey-200': theme?.palette?.grey?.['200'] ?? '',
    '--color-grey-300': theme?.palette?.grey?.['300'] ?? '',
    '--color-grey-400': theme?.palette?.grey?.['400'] ?? '',
    '--color-grey-500': theme?.palette?.grey?.['500'] ?? '',
    '--color-grey-600': theme?.palette?.grey?.['600'] ?? '',
    '--color-grey-700': theme?.palette?.grey?.['700'] ?? '',
    '--color-grey-800': theme?.palette?.grey?.['800'] ?? '',
    '--color-grey-900': theme?.palette?.grey?.['900'] ?? '',
    '--color-grey-A100': theme?.palette?.grey?.['A100'] ?? '',
    '--color-grey-A200': theme?.palette?.grey?.['A200'] ?? '',
    '--color-grey-A400': theme?.palette?.grey?.['A400'] ?? '',
    '--color-grey-A700': theme?.palette?.grey?.['A700'] ?? '',

    '--color-success-main': theme?.palette?.success?.main ?? '',
    '--color-success-dark': theme?.palette?.success?.dark ?? '',
    '--color-success-light': theme?.palette?.success?.light ?? '',
    '--color-success-contrastText': theme?.palette?.success?.contrastText ?? '',

    '--color-warning-main': theme?.palette?.warning?.main ?? '',
    '--color-warning-dark': theme?.palette?.warning?.dark ?? '',
    '--color-warning-light': theme?.palette?.warning?.light ?? '',
    '--color-warning-contrastText': theme?.palette?.warning?.contrastText ?? '',

    '--color-error-main': theme?.palette?.error?.main ?? '',
    '--color-error-dark': theme?.palette?.error?.dark ?? '',
    '--color-error-light': theme?.palette?.error?.light ?? '',
    '--color-error-contrastText': theme?.palette?.error?.contrastText ?? '',

    '--color-info-main': theme?.palette?.info?.main ?? '',
    '--color-info-dark': theme?.palette?.info?.dark ?? '',
    '--color-info-light': theme?.palette?.info?.light ?? '',
    '--color-info-contrastText': theme?.palette?.info?.contrastText ?? '',

    '--background-default': theme?.palette?.background?.default ?? '',
    '--background-paper': theme?.palette?.background?.paper ?? '',

    '--text-primary': theme?.palette?.text?.primary ?? '',
    '--text-secondary': theme?.palette?.text?.secondary ?? '',
    '--text-disabled': theme?.palette?.text?.disabled ?? '',
  };
};

export const applyTheme = (theme) => {
  const themeObject = mapTheme(theme);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    root.style.setProperty(property, themeObject[property]);
  });
};
