import axios from 'axios';

const MAX_REQUESTS_COUNT = process.env.MAX_REQUESTS_COUNT ?? 6;
const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL ?? '';
const INTERVAL_MS = process.env.INTERVAL_MS ?? 10;
let PENDING_REQUESTS = 0;

// create new axios instance
const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

/**
 * Axios Request Interceptor
 */
axiosInstance.interceptors.request.use(function(config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);

        resolve(config);
      }
    }, INTERVAL_MS);
  });
});
/**
 * Axios Response Interceptor
 */
axiosInstance.interceptors.response.use(function(response) {
  return postProcessResponse(response, false);
}, function(error) {
  return postProcessResponse(error.response, true, error);
});

const postProcessResponse = (response = {}, isError, error) => {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
  if (isError) {
    return Promise.reject(error);
  } else {
    return Promise.resolve(response);
  }
};
export const CancelToken = axios.CancelToken;
export const isCancel = axios.isCancel;

export default axiosInstance;
