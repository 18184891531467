'use client';

import React from 'react';
import Script from 'next/script';

const GOOGLE_METRIC_ID = 'G-8ZFVC5E0KG';

function GoogleMetric() {
  return (
    <>
      <Script async={true} src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_METRIC_ID}`} />
      <Script id="google-metrika">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', '${GOOGLE_METRIC_ID}');
      `}
      </Script>
    </>
  );
}

export default GoogleMetric;
