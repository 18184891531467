import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Backdrop, CircularProgress } from '@mui/material';

const LOADER_THRESHOLD = 250;

function NavigationBackdrop() {
  const [isPageLoading, setPageLoading] = useState(false);
  const router = useRouter();

  React.useEffect(() => {
    let timer;

    const start = () => {
      timer = setTimeout(() => setPageLoading(true), LOADER_THRESHOLD);
    };

    const end = () => {
      if (timer) {
        clearTimeout(timer);
      }
      setPageLoading(false);
    };

    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);

    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);

      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [router.events]);

  return (
    <Backdrop open={isPageLoading} className="container shadow-xl bg-grey/30">
      <CircularProgress color="info" />
    </Backdrop>
  );
}

export default NavigationBackdrop;
