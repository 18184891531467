const navigationRoutes = {
  MAIN_PAGE: {
    path: '/',
    i18nLabel: 'myPageNavLabel',
  },
  LISTS: {
    path: '/lists',
    i18nLabel: 'myListsNavLabel',
  },
  GIFTS_PAGE: {
    path: '/gifts',
    i18nLabel: 'myGiftsNavLabel',
  },
  COLLABORATIONS_PAGE: {
    path: '/collaborations',
    i18nLabel: 'collaborationsNavLabel',
  },
  INTERESTS_PAGE: {
    path: '/interests',
    i18nLabel: 'interestsNavLabel',
  },
};


export default navigationRoutes;
