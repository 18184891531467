import { STATUSES } from 'constants/api';

export const USER_PRESENTS_BY_STATUS = [
  STATUSES.LOADED,
  STATUSES.SAVING,
  STATUSES.SAVED,
  STATUSES.FAILED,
];

export const USER_TOKEN_COOKIE_NAME = 'OMG_YA_ID_TOKEN';
