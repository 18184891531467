import React, { useEffect, useMemo, useState } from 'react';
import Dropdown from '@ui-kit/components/Dropdown/Dropdown';
import Image from 'next/image';
import yaIdIcon from '@ui-kit/components/Header/ya_id_icon.svg';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { loginToYandexID } from '@ui-kit/hooks/useLogin';
import {
  fetchUserData as fetchUserDataAPI,
  fetchUserMetaData as fetchUserMetaDataAPI,
  logout as logoutAPI,
} from '@api/userAPI';
import isEmpty from 'lodash/isEmpty';
import { USER_TOKEN_COOKIE_NAME } from 'constants/user';
import { useRouter } from 'next/router';
import { deleteCookie, getCookie } from 'cookies-next';
import BlocksSpinner from '@ui-kit/components/Spinner/Blocks';

function AccountButton() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const token = getCookie(USER_TOKEN_COOKIE_NAME);
  const [user, setUser] = useState();
  const [userMetadata, setUserMetadata] = useState();

  useEffect(() => {
    setLoading(true);
    fetchUserDataAPI(token).then(({ data }) => {
      setUser(data);
      return fetchUserMetaDataAPI(token);
    }).then(({ data }) => {
      setUserMetadata(data);
    }).finally(() => {
      setLoading(false);
    });
  }, [token]);

  const userDisplayName = user?.display_name;
  const router = useRouter();

  const userButton = useMemo(() => (
    <Typography
      variant="inherit"
      color="text.secondary"
      className="w-20 md:w-fit truncate"
    >
      {userDisplayName}
    </Typography>
  ), [userDisplayName]);

  const dropdownList = useMemo(() => ([
    // { value: t('me') },
    // { value: t('accountSettings') },
    {
      value: t('signOut'), onClick: () => {
        logoutAPI().then(() => {
          deleteCookie(USER_TOKEN_COOKIE_NAME);
        }).then(() => router.replace('/'));
      },
    },
  ]), [t]);

  return loading
    ? (
      <div className="px-2 lg:px-[30px] shadow-button">
        <BlocksSpinner size={40} className="w-24" />
      </div>
    )
    : (isEmpty(user)
        ? (
          <Image
            src={yaIdIcon}
            onClick={loginToYandexID}
            alt={t('signIn')}
            className="shadow-button cursor-pointer rounded-12 bg-[black]"
          />
        )
        : (
          <Dropdown
            left
            caret={false}
            buttonContent={userButton}
            dropdownHeader={userDisplayName}
            dropdownList={dropdownList}
          />
        )
    );
}

export default AccountButton;
