import { createTheme } from '@mui/material/styles';
import { Golos_Text } from 'next/font/google';

const golosText = Golos_Text({
  subsets: ['latin-ext', 'cyrillic-ext'],
  display: 'block',
});

const themeOverrides = createTheme({
  typography: {
    fontFamily: golosText.style.fontFamily,
  },
  palette: {
    primary: {
      main: 'rgb(5, 178, 189)',
    },
    success: {
      main: 'rgb(3, 201, 215)',
      dark: 'rgb(5, 178, 189)',
    },
    error: {
      main: 'rgb(228, 106, 118)',
      dark: 'rgb(228, 90, 104)',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    PrivatePickersYear: {
      styleOverrides: {
        button: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        header: {
          display: 'none',
        },
        weekDayLabel: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'rgba(90, 114, 123, 0.11) 0px 7px 30px 0px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'rgba(90, 114, 123, 0.11) 0px 7px 30px 0px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '1.675rem',
          fontWeight: 600,
          lineHeight: 1.235,
        },
      },
    },
  },
});

export default themeOverrides;
