'use client';

import React, { useEffect } from 'react';
import Script from 'next/script';
import { usePathname, useSearchParams } from 'next/navigation';

const YANDEX_METRIC_ID = '96346846';

function YandexMetric() {
  const pathName = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    ym?.(YANDEX_METRIC_ID, 'hit', window.location.href);
  }, [pathName, searchParams]);

  return (
    <>
      <Script id="yandex-metrika">
        {`
         (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
         m[i].l=1*new Date();
         for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
         k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
         (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      
         ym(${YANDEX_METRIC_ID}, "init", {
              defer: true,
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              webvisor:true
         }); 
      `}
      </Script>
      <noscript>
        <div>
          <img src={`https://mc.yandex.ru/watch/${YANDEX_METRIC_ID}`} style="position:absolute; left:-9999px;" alt="" />
        </div>
      </noscript>
    </>
  );
}

export default YandexMetric;
