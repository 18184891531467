import React from 'react';
import { List, ListItem } from '@mui/material';
import LanguageButton from '@ui-kit/layout/components/LanguageButton';
import AccountButton from '@ui-kit/components/Header/AccountButton';

function RightSide() {
  return (
    <List className="m-0 py-0 pl-0 list-none flex gap-1">
      <ListItem className="mui-list-item w-content">
        <LanguageButton language="ru" title="ру" />
      </ListItem>
      <ListItem className="mui-list-item w-content">
        <LanguageButton language="en" title="en" />
      </ListItem>
      <ListItem className="mui-list-item w-content">
        <AccountButton />
      </ListItem>
    </List>
  );
}

export default RightSide;
