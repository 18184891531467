import axios from '@api/axiosInstance';
import isEmpty from 'lodash/isEmpty';
import { getCookie } from 'cookies-next';
import { USER_TOKEN_COOKIE_NAME } from 'constants/user';

export function fetchUserData(userToken) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.get(`/api/user?token=${token}`);
}

export function fetchUserMetaData(userToken) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.get(`/api/userMetadata?token=${token}`);
}

export function getUserByLogin(userLogin) {
  return axios.get(`/api/userByLogin?userLogin=${userLogin}`);
}

export function toggleInterestingList(listId, userToken) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.put(`/api/toggleInterestingList?token=${token}&listId=${listId ?? ''}`);
}

export function toggleFriend(userId, userToken) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.put(`/api/toggleFriend?token=${token}&userId=${userId ?? ''}`);
}

export function logout(userToken) {
  const token = isEmpty(userToken)
    ? (getCookie(USER_TOKEN_COOKIE_NAME) ?? '')
    : userToken;
  return axios.post(`/api/logoutUser?token=${token}`);
}
