'use client';

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import YandexMetric from '@ui-kit/layout/components/metrics/YandexMetric';
import GoogleMetric from '@ui-kit/layout/components/metrics/GoogleMetric';
import isEmpty from 'lodash/isEmpty';

function Metrics() {
  const router = useRouter();
  const [host, setHost] = useState('');

  useEffect(() => {
    setHost(window.location.host ?? '');
  }, [router.asPath]);

  return (isEmpty(host) || host.startsWith('localhost') || host.startsWith('127.0.0.1'))
    ? null
    : (
      <>
        <YandexMetric />
        <GoogleMetric />
      </>
    );
}

export default React.memo(Metrics);
