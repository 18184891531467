import React from 'react';
import clsx from 'clsx';
import map from 'lodash/map';
import { List, ListItem, Typography } from '@mui/material';
import navigationRoutes from 'constants/navigationRoutes';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';

const draftNavigationRoutes = [
  {
    path: navigationRoutes.MAIN_PAGE.path,
    i18nLabel: navigationRoutes.MAIN_PAGE.i18nLabel,
  },
  {
    path: navigationRoutes.LISTS.path,
    i18nLabel: navigationRoutes.LISTS.i18nLabel,
  },
  {
    path: navigationRoutes.GIFTS_PAGE.path,
    i18nLabel: navigationRoutes.GIFTS_PAGE.i18nLabel,
  },
  {
    path: navigationRoutes.INTERESTS_PAGE.path,
    i18nLabel: navigationRoutes.INTERESTS_PAGE.i18nLabel,
  },
];

function Navigation({ vertical = false }) {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const pagePath = pathname?.split('/')?.[1];

  return (
    <List className={clsx('m-0 py-0 pl-0 flex gap-1', { 'flex-col': vertical })}>
      {map(draftNavigationRoutes, navigationRoute => {
        const linkLabelClassName = clsx(
          'py-3 px-4 rounded-3 hover:shadow-1 hover:bg-paper hover:text-primary-main whitespace-pre',
          {
            'text-primary-main underline': navigationRoute.path === `/${pagePath}`,
            'text-primary': navigationRoute.path !== `/${pagePath}`,
          },
        );
        return (
          <ListItem key={navigationRoute.path} className="mui-list-item">
            <Link shallow={true} prefetch={false} href={navigationRoute.path}>
              <Typography
                variant="body1"
                color="inherit"
                className={linkLabelClassName}
              >
                {t(navigationRoute.i18nLabel)}
              </Typography>
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
}

export default Navigation;
