import React from 'react';
import clsx from 'clsx';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(MuiButton)(() => ({
  margin: '.3125rem 1px',
  fontSize: '15px',
  fontWeight: '400',
  willChange: 'box-shadow, transform',
  transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  lineHeight: '1.75',
  '& .fab,& .fas,& .far,& .fal,& .material-icons': {
    position: 'relative',
    display: 'inline-block',
    top: '0',
    fontSize: '1.1rem',
    marginRight: '4px',
    verticalAlign: 'middle',
  },
  '& svg': {
    position: 'relative',
    display: 'inline-block',
    top: '0',
    width: '18px',
    height: '18px',
    marginRight: '4px',
    verticalAlign: 'middle',
  },
  '&$justIcon': {
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginRight: '0px',
      position: 'absolute',
      width: '100%',
      transform: 'none',
      left: '0px',
      top: '0px',
      height: '100%',
      lineHeight: '41px',
      fontSize: '20px',
    },
  },
}));

const Button = React.forwardRef(({
  round,
  fullWidth,
  className,
  variant,
  children,
  ...rest
}, ref) => {

  return (
      <StyledButton
          {...rest}
          ref={ref}
          variant={variant ?? 'contained'}
          className={clsx(
              'shadow-button min-h-auto min-w-auto border-none relative py-[6px] px-[16px] text-center my-0',
              'whitespace-nowrap cursor-pointer align-middle normal-case tracking-normal touch-manipulation hover:border-none',
              { 'w-full': fullWidth },
              round ? 'rounded-full' : 'rounded-[5px]',
              className,
          )}
      >
        {children}
      </StyledButton>
  );
});

export default Button;
